.NavigationLink {
    background: none;
    transition: background-color 150ms ease-in-out !important;
    border-radius: 10px;

    &.dark:hover:not(.disabled) {
        background: rgba(255, 255, 255, 0.1);
    }

    &.light:hover:not(.disabled) {
        background: rgba(0, 0, 0, 0.1);
    }


    & .disabled {
        filter: opacity(0.5)
    }
}
