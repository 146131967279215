.Notifications {
    .hiddenPanel {
        width: 400px;
        min-height: 100vh;
        border-left: 1px solid;
        display: flex;
        flex-direction: column;

        & .notificationsPanel {
            padding: 16px 16px;
    
            & .notificationContent {
                display: flex;
                flex-direction: column;
                align-items: center;
        
                & .notificationFullWidth {
                    width: 100%;
        
                    & .notificationContainer {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        width: 100%;
                    }
                }
            }
        }
        
        & .header {
            display: inline-flex;
            position: sticky;
            top: 0;
            width: 100%;
            height: fit-content;
            z-index: 50;
            padding: 16px 8px;
            gap: 8px;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid;
        
            & .title {
                flex-grow: 0.9;
                flex-direction: column;
            }
        }
    }
}
