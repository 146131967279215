.Logo {
    display: flex;

    .none {
        fill: none;
    }

    .darkRed {
        fill: #a21a1a;
    }

    .white {
        fill: #ffffff;
    }

    .black {
        fill: #000000;
    }

    .red {
        fill: #e42229;
    }
}
