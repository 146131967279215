.DataTable {
    .buttonBar {
        display: flex;
        margin-bottom: 8px;
        gap: 8px;
        width: 100%;
        position: relative;

        & .quickFilter {
            position: absolute;
            right: 0;
        }
    }

    .columnHeader {
        & .MuiDataGrid-columnHeaderTitle {
            font-weight: bold;
            // overflow: visible !important; doesn't seem to be needed. Enable if problems arise
        }
    }
}
