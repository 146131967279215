.HeaderMain {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;

    .bar {
        border-bottom: 1px solid;
        display: flex;
        flex-direction: row;
        padding: 8px 16px;
        width: 100%;
    
        & > * {
            flex-direction: row;
        }
    }
    
    .left {
        width: fit-content;
    }
    
    .right {
        display: inline-flex;
        margin-left: auto;
        align-self: center;
    }
}
