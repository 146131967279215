.LabeledIconButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px;
    min-width: 130px;
    min-height: 108px;
    border: 0.5px solid;
    transition-duration: 300ms;
    cursor: pointer;

    &.selected {
        border: 4px solid;
        background-color: rgba(50, 50, 50, 0.35);
    }

    &:hover {
        background-color: rgba(50, 50, 50, 0.35);
    }
}