.addItemModal {
    & .actions {
        display: flex;
        margin-inline: 4px;
        gap: 8px;
    }

    & .fieldContainer {
        width: 250px;
        margin-inline: 4px;
        margin-block: 8px;
    }

    & .textAreaContainer {
        grid-column: span 3;
        margin-inline: 4px;
        margin-block: 8px;
    }

    & .fields {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        justify-content: stretch;
    }

    & .textAreaFields {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: stretch;
    }
}