.mainContainer {
    border-bottom: 1px solid;
    display: flex;
    padding: 8px 16px;
    margin-top: -16px;
    margin-bottom: 16px;
    width: 100%;
    height: 96px;
    align-items: center;
    gap: 8px;
}

.cardContainer {
    display: grid;
    gap: 16px;
    width: 100%;
}

.tabpanel {
    display: flex;
    width: 100%;
}
