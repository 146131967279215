.DataTableController {
    width: 100%;
    padding: 16px;

    .formControl {
        margin-bottom: 8px;
    }

    .selectTabella {
        width: 310px;
    }
}