.SidebarMain {
    z-index: 100;

    &:has(.locked) {
        position: sticky;
        top: 0;
        left: 0;
        margin-right: calc(210px - 56px);
    }

    .bar {
        border-right: 1px solid;
        display: flex;
        flex-direction: row;
        padding: 8px 8px;
        height: 100dvh;
        overflow: hidden;
        width: 56px;
        transition: width 150ms ease-in-out !important;
        position: fixed;

        &.locked {
            width: 210px;
        }
    
        &:not(.locked):hover {
            width: 210px;
        }
    }
    
    .navigation {
        & > * {
            margin-block: 4px;
        }
    }
}
